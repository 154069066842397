<template>
  <app-form-view
    multipart
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.personalDocument')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveFields"
    :prepare-data="prepareData"
    v-model="formData"
    @input="loadDocuments"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" md="6">
          <v-row
            ><v-col cols="12" lg="12">
              <app-input
                name="name"
                :label="$t('fields.documentName')"
                :view="view"
                v-model="formData.document_name"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="contact"
                type="chips-server"
                rules="required"
                :label="$t('fields.contact')"
                :view="view"
                :binds="{
                  apiUrl: `contact/contact/?contact_type=individual`,
                  chip: {
                    color: 'secondary',
                    icon: 'mdi-account-circle-outline',
                    value: 'name',
                    router: contactRouter
                  }
                }"
                :filterObject="filterObject"
                v-model="formData.contact_ids"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_contact_ids"
              />
            </v-col>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="type"
                rules="required"
                :label="$t('fields.type')"
                :view="view"
                value="individual"
                disabled
              >
              </app-input>

              <app-input
                v-if="isSentToApprove"
                name="approveType"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="documentTemplate"
                type="select-server"
                rules="required"
                :label="$t('fields.documentTemplate')"
                :view="view"
                :binds="{
                  apiUrl: `doc-template/document/?active=true&contact_type=individual`
                }"
                v-model="formData.document_template_id"
              />

              <app-input
                v-if="isSentToApprove"
                name="approveDocumentTemplate"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_template_id"
              />
            </v-col>
            <template v-if="formData.document_template_id">
              <v-col
                v-for="field in documentTemplateFields"
                cols="12"
                md="6"
                class="app-input--approve-wrapper"
                :key="field.field"
              >
                <app-input
                  :rules="{
                    required: true,
                    numeric: field.field_type === 'number'
                  }"
                  :name="field.field"
                  :type="field.field_type"
                  :label="field.name"
                  :items="fieldSelectionItems(field)"
                  :view="view"
                  v-model="formData.fields[field.field]"
                />

                <app-input
                  v-if="isSentToApprove"
                  type="switch"
                  class="approve"
                  :name="`approve-${field.name}`"
                  :label="$t('fields.approve')"
                  v-model="formData[`approve_${field.field}`]"
                />
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="customer_name"
                type="select-server"
                rules="required"
                :label="$t('fields.customerCompany')"
                :view="view"
                :binds="{
                  apiUrl: 'customer-company/customer_company/?active=true'
                }"
                v-model="formData.customer_company_id"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveCustomerCompany"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_customer_company"
              />
            </v-col>
          </v-row>
          <v-row v-if="!view">
            <v-col cols="12" lg="12">
              <app-input
                name="documents"
                type="file"
                rules="required"
                :label="$t('fields.document')"
                :view="view"
                :binds="{ multiple: true, chips: true }"
                v-model="formData.documents"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="12">
              <app-input
                name="note"
                type="textarea"
                :label="$t('fields.note')"
                :view="view"
                v-model="formData.note"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="12">
              <app-input
                name="active"
                type="switch"
                :label="$t('fields.active')"
                :view="view"
                v-model="formData.active"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <app-preview-files
            :file-ready="documentReady || mode === 'create'"
            :files="formData.documents || []"
          />
        </v-col>
      </v-row>

      <slot :view="view" />
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppPreviewFiles from '@components/AppPreviewFiles'
import { getFile } from '@utils/function'

export default {
  name: 'business-industry-form',
  components: {
    AppInput,
    AppFormView,
    AppPreviewFiles
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    documentTemplateFields() {
      const fields = [...this.formData.document_template_id.fields]
      fields.sort((a, b) => {
        if (a.code < b.code) return -1
        if (a.code > b.code) return 1
        return 0
      })
      return fields
    },
    approveFields() {
      return this.formData.document_template_id
        ? [...this.formData.document_template_id.fields]
            .map((field) => 'approve_' + field.field)
            .concat([
              'approve_contact_ids',
              'approve_type',
              'approve_document_template_id',
              'approve_document_name',
              'approve_customer_company'
            ])
        : []
    }
  },
  data() {
    return {
      documentReady: false,
      formData: {
        state: 'draft',
        fields: {}
      }
    }
  },
  created() {
    if (this.$route.name === 'personalDocumentCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'personalDocumentCreate'
      })
    }
  },
  watch: {
    'formData.document_name': function (value) {
      if (this.$route.name === 'personalDocumentEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'personalDocumentEdit',
          text: value,
          to: {
            name: 'personalDocumentEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.national_id_no
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    async loadDocuments() {
      this.formData.documents = await Promise.all(
        this.formData.documents.map((document) => getFile(document))
      )
      this.documentReady = true
    },
    fieldSelectionItems(field) {
      if (field.field_type !== 'select') return []
      return field.selection_members.map((value) => ({
        ...value,
        value: value.label
      }))
    },
    prepareData(data) {
      data.contact_ids = data.contact_ids.map((value) => value.id)
      data.document_template_id = data.document_template_id.id
      data.customer_company_id = data.customer_company_id.id
      data.type = 'individual'
      return data
    }
  }
}
</script>
